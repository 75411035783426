import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { PushPipe } from '@ngrx/component';
import { AngularMaterialModule } from 'core-global-frontend-angular-material';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

@Component({
  selector: 'frontend-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    AngularMaterialModule,
    MatMenuModule,
    RouterModule,
    PushPipe,
    MatButtonModule,
  ],
})
export class ToolbarComponent {
  userName?: string;
  @Input() isDebug = false;
  @Input() showDebugToggle= false;
  @Input() showTicketCreationLinks = false;

  constructor(
    _activedRoute: ActivatedRoute,
    private _router: Router,
    @Inject(OKTA_AUTH) public _oktaAuth: OktaAuth,
  ) {
    this._oktaAuth.authStateManager.subscribe(authState => {
      if (authState.isAuthenticated) {
        this._oktaAuth.getUser().then(user => {
          this.userName = user.name;
        });
      }
    });
  }

  logout() {
    this._oktaAuth?.revokeAccessToken();
    this._oktaAuth?.revokeRefreshToken();
    this._oktaAuth?.signOut({
      revokeRefreshToken: true,
      revokeAccessToken: true,
      clearTokensBeforeRedirect: true
    });
  }

  toggleDebug(event: any) {
    this._router.navigate([this.getCurrentPageBaseURLWithoutQueryString()], {
      queryParams: { debug: event.checked },
      queryParamsHandling: 'merge',
    });
  }

  getCurrentPageBaseURLWithoutQueryString() {
    return this._router
      .parseUrl(this._router.url)
      .root.children['primary'].segments.map(it => it.path)
      .join('/');
  }
}
