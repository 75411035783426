import { ValidationErrors, AbstractControl } from '@angular/forms';

export function DomainNameValidator(control: AbstractControl): ValidationErrors {
  return /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(
    control.value
  ) &&
    !/([-]{2})/.test(control.value) &&
    control.value.match(/\./g).length == 1 &&
    control.value.length < 64
    ? {}
    : { 'domain-name-validator': true };
}
