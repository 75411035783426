export const environment = {
  appVersion: 'cede9',
  buildVersion: '12321585130',
  schemaFolder: 'main',
  host: 'https://partner-ui.ramp.system1.com',
  name: 'prod',
  tableauUrl: 'https://10az.online.tableau.com/#/site/s1partnerreports/views',
  oktaRedirect: 'https://partner.system1.com/login/callback',
  oktaDisableHttpsCheck: false,
  pkce: true,
  cookieSecure: true,
  applicationName: 'partner-ui',
  apis: {
    PartnerAPI: 'https://partner-api.ramp.system1.com',
    RampPartnerAPI: 'https://iwbakhldml.execute-api.us-east-1.amazonaws.com/api',
    RampAPI: 'https://ramp-api.system1.com/api',
    RetoolEmbedAPI: 'https://retool-api.ramp.system1.com',
    NotificationAPI: 'https://notification-service-processor-us-west-2.nimbus.system1.company'
  },
  log: {
    rndViewerbufferSize: 0,
    useConsole: true,
    showAPICalls: false
  },
  embedIds: {
    'content-generator': {
      pageUuid: '7d5cc037-286d-4da5-99e8-2eda390ef829',
      groupId: 3182834,
    },
    'url-builder': {
      pageUuid: '40de4d90-fe9f-4742-9e91-a6446921933b',
      groupId: 3182833,
    },
  },
  nimbusApiKey: '692f9d02-a2cd-4744-aa67-e1924174ab3d',
  googleTag: 'G-NZ1YFLKZF5',
};
