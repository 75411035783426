<div class="formly-field-input container">
  <svg
    *ngIf="field.props['showInfoButton']"
    [matTooltip]="field.props['toolTip']"
    role="img"
    class="formly-field-input svg">
    <use xlink:href="assets/img/sprites.svg#ramp-info-icon" />
  </svg>
  <mat-form-field class="mat-form-field--no-padding" [floatLabel]="'always'">
    <mat-label *ngIf="!ovs.isNullOrEmpty(field.props['label'])">{{
      field.props['label']
    }}</mat-label>
    <input
      (focus)="onFocusEvent($event)"
      type="text"
      matInput
      [formControl]="formControl"
      [placeholder]="''"
      [required]="field.props['required']" />
  </mat-form-field>
  <span class="mat-mdc-form-field-error text" role="alert">
    <div
      class="place-holder"
      *ngIf="!(formControl.touched && !formControl.valid) || field.props['keepPlaceholder']">
      {{ field.props['placeHolderText'] || '&nbsp;' }}
    </div>
    <formly-validation-message
      [field]="field"
      *ngIf="
        formControl.touched && !formControl.valid
      "></formly-validation-message>
  </span>
</div>
