import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeadersBuilderFactory } from './http-headers-builder.service';
import { HttpOptionsBuilderFactory } from './http-options-builder.service';
import { HttpUrlBuilderFactory } from './http-url-builder.service';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Injectable({
  providedIn: 'root',
})
export class HttpClientQ {
  constructor(
    private _httpClient: HttpClient,
    private _httpHeadersBuilderFactory: HttpHeadersBuilderFactory,
    private _httpOptionsBuilderFactory: HttpOptionsBuilderFactory,
    private _httpUrlBuilderFactory: HttpUrlBuilderFactory,
    private _ovs: ObjectValidatorService,
  ) {}

  get(
    url: string,
    parameters?: any,
    token?: any,
    contentType?: string,
  ): Observable<any> {
    return this._httpClient.get<any>(
      this.getDefaultUrl(url),
      this.getDefaultOptions(parameters, token, contentType),
    );
  }

  getResponse(
    url: string,
    parameters: any = null,
    token?: any,
  ): Observable<HttpResponse<any>> {
    const options: any = this._httpOptionsBuilderFactory.builder
      .withHeaders(this.getDefaultHeaders(token))
      .withObserve('response')
      .withParameters(parameters)
      .build() as any;
    return this._httpClient.get<any>(this.getDefaultUrl(url), options) as any;
  }

  getFile(url: string, parameters?: any, token?: any) {
    const headers = this._httpHeadersBuilderFactory.builder
      .withAuthorization(token)
      .build();
    const options = this._httpOptionsBuilderFactory.builder
      .withHeaders(headers)
      .withResponseType('blob')
      .withParameters(parameters)
      .withCredentials(true)
      .build();
    return this._httpClient.get(this.getDefaultUrl(url), options);
  }

  postBodyForFile(url: string, body?: any, parameters?: any, token?: any) {
    const headers = this._httpHeadersBuilderFactory.builder
      .withAuthorization(token)
      .build();
    const options = this._httpOptionsBuilderFactory.builder
      .withHeaders(headers)
      .withResponseType('blob')
      .withParameters(parameters)
      .withCredentials(true)
      .build();
    return this._httpClient.post(this.getDefaultUrl(url), body, options);
  }

  postFile(url: string, file?: FormData, parameters?: any, token?: any) {
    const headers = this._httpHeadersBuilderFactory.builder
      .withAuthorization(token)
      .build();
    const options = this._httpOptionsBuilderFactory.builder
      .withHeaders(headers)
      .withParameters(parameters)
      .withCredentials(true)
      .build();
    return this._httpClient.post(url, file, options);
  }

  post(url: string, body: any, parameters?: any, token?: any): Observable<any> {
    return this._httpClient.post<any>(
      this.getDefaultUrl(url),
      this._ovs.cleanObject(body),
      this._httpOptionsBuilderFactory.builder
        .withParameters(parameters)
        .withHeaders(this.getDefaultHeaders(token))
        .build(),
    );
  }

  postResponse(
    url: string,
    body?: any,
    token?: any,
  ): Observable<HttpResponse<any>> {
    const options: any = this._httpOptionsBuilderFactory.builder
      .withHeaders(this.getDefaultHeaders(token))
      .withObserve('response')
      .build() as any;
    return this._httpClient.post<any>(
      this.getDefaultUrl(url),
      this._ovs.cleanObject(body),
      options,
    ) as any;
  }

  delete(url: string, parameters?: any, token?: any): Observable<any> {
    return this._httpClient.delete<any>(
      this.getDefaultUrl(url),
      this.getDefaultOptions(parameters, token),
    );
  }

  deleteResponse(url: string, token?: any): Observable<HttpResponse<any>> {
    const options: any = this._httpOptionsBuilderFactory.builder
      .withHeaders(this.getDefaultHeaders(token))
      .withObserve('response')
      .build() as any;
    return this._httpClient.delete<any>(this.getDefaultUrl(url), options) as any;
  }

  patch(
    url: string,
    body?: any,
    parameters?: any,
    token?: any,
  ): Observable<any> {
    return this._httpClient.patch<any>(
      this.getDefaultUrl(url),
      this._ovs.cleanObject(body),
      this.getDefaultOptions(parameters, token),
    );
  }

  patchResponse(
    url: string,
    body?: any,
    token?: any,
  ): Observable<HttpResponse<any>> {
    const options: any = this._httpOptionsBuilderFactory.builder
      .withHeaders(this.getDefaultHeaders(token))
      .withObserve('response')
      .build() as any;
    return this._httpClient.patch<any>(
      this.getDefaultUrl(url),
      this._ovs.cleanObject(body),
      options,
    ) as any;
  }

  put(url: string, body?: any, parameters?: any, token?: any): Observable<any> {
    return this._httpClient.put<any>(
      this.getDefaultUrl(url),
      this._ovs.cleanObject(body),
      this.getDefaultOptions(parameters, token),
    );
  }

  putResponse(
    url: string,
    body?: any,
    token?: any,
  ): Observable<HttpResponse<any>> {
    const options: any = this._httpOptionsBuilderFactory.builder
      .withHeaders(this.getDefaultHeaders(token))
      .withObserve('response')
      .build() as any;
    return this._httpClient.put<any>(
      this.getDefaultUrl(url),
      this._ovs.cleanObject(body),
      options,
    ) as any;
  }

  private getDefaultHeaders(token?: any, contentType?: string): HttpHeaders {
    return this._httpHeadersBuilderFactory.builder
      .withContentType(contentType)
      .withAuthorization(token)
      .build();
  }

  private getDefaultOptions(
    parameters?: any,
    token?: any,
    contentType?: string,
  ): any {
    return this._httpOptionsBuilderFactory.builder
      .withParameters(parameters)
      .withHeaders(this.getDefaultHeaders(token, contentType))
      .build();
  }

  private getDefaultUrl(url: string): string {
    return this._httpUrlBuilderFactory.getBuilder(url).build();
  }
}
