<div class="container-wrapper">
  <div *ngIf="showCloseButton" class="modal-close__container">
    <div class="modal-close__button" (click)="$event.preventDefault(); confirm(false)">
      <svg role="img">
        <use xlink:href="assets/img/sprites.svg#close-icon-no-circle" />
      </svg>
    </div>
  </div>
  <h2>{{ message }}</h2>
  <h3 *ngIf="!this.ovs.isNullOrEmpty(subMessage)">{{ subMessage }}</h3>
  <form class="custom-confirmation" [formGroup]="form" (ngSubmit)="confirm(true)">
    <lib-form-container
      *ngIf="!ovs.isNullOrEmpty(fields)"
      [defaultButtons]="false"
      [fields]="fields"
      [form]="form"
      [model]="model">
    </lib-form-container>
    <div *ngIf="!ovs.isNullOrEmpty(options) && ovs.isNullOrEmpty(fields)">
      <div class="custom-confirmation__dropdown">
        <lib-searchable-select-control
          [control]="selectedItemControl"
          [searchableListItems]="options"
          [sortOption]="searchableSelectSortOption"
          [enableMultiSelect]="false">
        </lib-searchable-select-control>
        <mat-error *ngIf="!selectedItemControl.valid && _required">
          Selection is required.</mat-error
        >
      </div>
      <br />
    </div>
    <button
      *ngIf="ovs.isDefined(fields)"
      mat-flat-button
      color="accent"
      [disabled]="!form.valid"
      type="submit"
      class="mr-2">
      {{ submitButtonName }}
    </button>
    <button
      *ngIf="!ovs.isDefined(fields)"
      mat-flat-button
      color="accent"
      [disabled]="isDisable()"
      type="submit"
      class="mr-2"
      data-test="confirmation-submit-button">
      {{ submitButtonName }}
    </button>
    <button
      *ngIf="showCancelButton"
      mat-flat-button
      (click)="$event.preventDefault(); confirm(false)">
      Cancel
    </button>
  </form>
</div>
