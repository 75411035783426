<mat-form-field *ngIf="editable">
  <input matInput [placeholder]="column.label" [formControl]="field" />
</mat-form-field>
<frontend-static-text-cell
  (click)="onClick()"
  [form]="form"
  [editable]="editable"
  [column]="column"
  *ngIf="!editable"
></frontend-static-text-cell>
<div>Dirty: {{ form.dirty }}</div>
