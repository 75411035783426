import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, take } from 'rxjs';
import { SessionManagerService } from '@nimbus/global-frontend-subscription-manager';

/**
 * Configuration service for the frontend
 * Before accessing make sure to subscribe to configured$
 *  to make sure that the service has been initialized before values are read from.
 */
@Injectable({
  providedIn: 'root',
})
export class ConfigService {

  private _okta!: {
    authorizationServers: { [id: string]: string };
    clientId: string;
    issuer: string;
    redirectUrl: string;
    server: string;
  };
  envName = '';
  public get okta(): {
    authorizationServers: { [id: string]: string };
    clientId: string;
    issuer: string;
    redirectUrl: string;
    server: string;
  } {
    return this._okta;
  }
  private readonly _configuredSubject = new ReplaySubject<boolean>();
  configured$: Observable<boolean> = this._configuredSubject.asObservable().pipe(take(1));
  private _env = 'stage';
  public get env() {
    return this._env;
  }
  schemaFolder = 'main';
  private _apis: { [id: string]: string } | undefined;
  private _applicationName: string | undefined;
  private _primaryApiName: string | undefined;
  private _nimbusApiKey: string | undefined;
  private _host: string | undefined;

  constructor(private _sessionManager: SessionManagerService) {
    this._sessionManager.startupCleaning();
  }

  public init(params: {
    schemaFolder: string;
    apis: { [id: string]: string };
    envName: string;
    applicationName: string;
    oktaConfig: {
      authorizationServers: { [id: string]: string };
      clientId: string;
      issuer: string;
      redirectUrl: string;
      server: string;
    };
    primaryApiName: string;
    nimbusApiKey: string;
    host: string;
  }): void {
    this._primaryApiName = params.primaryApiName;
    this._okta = params.oktaConfig;
    this.schemaFolder = params.schemaFolder;
    this._apis = params.apis;
    this._applicationName = params.applicationName;
    this._nimbusApiKey = params.nimbusApiKey;
    this._host = params.host;
    this.envName = params.envName;
    this._configuredSubject.next(true);
  }

  get applicationName(): string | undefined {
    return this._applicationName;
  }

  getApi(apiId: string | undefined = this._primaryApiName): any {
    return this._apis?.[apiId || ''];
  }

  getNimbusApiKey(): string | undefined {
    return this._nimbusApiKey;
  }

  getHost(): string | undefined {
    return this._host;
  }

  inProductionMode(): boolean {
    return this.envName === 'prod' || this.envName.startsWith('uat');
  }

  isLocal(): boolean {
    return this.envName !== 'prod' && this.envName !== 'stage' && !this.envName.startsWith('uat') && !this.envName.startsWith('preview');
  }
}
