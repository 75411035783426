<div class="container" title="Edit Access Token">
  <div *ngIf="!(pixelManagerEditService.inEditMode$|ngrxPush) || ((pixelManagerEditService.inEditMode$|ngrxPush) !== uid)" class="one-row">
    <svg *ngIf="!(pixelManagerEditService.inEditMode$|ngrxPush)" role="img" (click)="$event.stopPropagation();edit();">
      <use xlink:href="assets/img/sprites.svg#partner-pencil-icon" />
    </svg>
    <span>
      {{ form.controls.accessToken.value }}
    </span>
  </div>
  <div
    *ngIf="(pixelManagerEditService.inEditMode$|ngrxPush) && ((pixelManagerEditService.inEditMode$|ngrxPush) === uid)">
    <app-edit-access-token-input [accessToken]="form.controls.accessToken.value" (editRequested)="editRequested($event)"
      (cancelEdit)="cancelEditMode()">
    </app-edit-access-token-input>
  </div>
</div>
