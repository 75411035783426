import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  TokenCacheService,
} from '@nimbus/shared-lib';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { PixelAccessTokenModel } from '../../models/pixel-access-token.model';
import { PixelManagerCacheService } from '../../services/pixel-manager-cache.service';
import { PixelManagerEditService } from '../../services/pixel-manager-edit.service';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ColumnInfo } from 'core-global-frontend-grid';
import {
  AngularMaterialModule,
  NimbusFormsModule,
} from 'core-global-frontend-common-ui';
import { ConfirmationService } from '@nimbus/shared-lib';
import { CommonModule } from '@angular/common';
import { EditAccessTokenInputComponent } from './edit-access-token-input/edit-access-token-input.component';
import { ImpersonationTrackerService } from '../../../../shared/services/impersonation-tracker.service';
import {
  ICustomCell,
  IClickableCell,
} from 'core/libs/global/frontend/grid/src/lib/cells/icell-component';
import { PushPipe } from '@ngrx/component';
import { EMPTY } from 'rxjs';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';

@Component({
  selector: 'edit-access-token',
  templateUrl: './edit-access-token.component.html',
  styleUrls: ['./edit-access-token.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AngularMaterialModule,
    NimbusFormsModule,
    ReactiveFormsModule,
    EditAccessTokenInputComponent,
    FormsModule,
    PushPipe
  ],
})
export class EditAccessTokenComponent
  implements ICustomCell, OnDestroy, IClickableCell {
  @Input() form: FormGroup<any>;
  @Input() column: ColumnInfo;
  @Input() editable: boolean;
  @Input() rowIndex: number;
  @Input() transform?: (input: string) => string;
  @Input() dataSource: MatTableDataSource<FormGroup<any>, MatPaginator>;
  @Output() clickEvent = new EventEmitter<ICustomCell>();
  readonly uid = Date.now();
  private _subscriptionManager = new SubscriptionManager();

  constructor(
    private _snackbarService: SnackbarService,
    private _pixelManagerCacheService: PixelManagerCacheService,
    private _webServiceBuilderService: NimbusWebServiceBuilderService,
    private _impersonationTrackerService: ImpersonationTrackerService,
    private _tokenCacheService: TokenCacheService,
    private _ovs: ObjectValidatorService,
    public pixelManagerEditService: PixelManagerEditService,
    private _confirmationService: ConfirmationService
  ) { }

  edit() {
    this.pixelManagerEditService.updateEditState(this.uid, true);
  }

  submitEdit(editedAccessToken: string) {
    this._snackbarService.open(
      'Processing your request.',
      SnackBarType.working,
      3000,
    );
    this.pixelManagerEditService.updateEditState(this.uid, false);

    this._subscriptionManager.register(
      this._tokenCacheService.email
        .pipe(
          tap(() => this._snackbarService.open('Request processed!', SnackBarType.done, 3000,)),
          switchMap(email => {
            const pixel = new PixelAccessTokenModel(
              this.form.controls.partnerId.value,
              this.form.controls.buysideNetworkId.value,
              this.form.controls.pixelId.value,
              editedAccessToken,
              this.form.controls.id.value,
              this._ovs.isNullOrEmpty(this._impersonationTrackerService.user)
                ? email
                : this._impersonationTrackerService.user.email
            );
            return this._webServiceBuilderService.builder
              .withUrl(`pixels/${pixel.email}/accesstokens/${pixel.id}`)
              .build()
              .save(pixel);
          }
          ),
          tap(() => this._pixelManagerCacheService.load(true)),
          catchError(err => {
            this._snackbarService.open(err.message, SnackBarType.error, 3000);
            throw EMPTY;
          })
        )
        .subscribe()
    );
  }

  cancelEditMode() {
    this.pixelManagerEditService.updateEditState(this.uid, false);
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }

  editRequested(accessToken: string) {
    return this._confirmationService.showAsync(
      'Warning',
      'Continue',
      null,
      null,
      true,
      true,
      null,
      null,
      'This edit will break conversion events leveraging the original mapping',
      true
    ).pipe(
      tap(confirm => {
        if (confirm) {
          this.submitEdit(accessToken);
        }
        else {
          this.cancelEditMode();
        }
      }),
    ).subscribe();
  }
}
