import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GlobalFrontendGlobalStoreModule } from 'core-global-frontend-global-store';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import {
  SiteMapNode,
  NavigatorComponent,
} from 'core-global-frontend-navigation';
import { OverlayComponent } from '../overlay/overlay.component';
import { HeaderComponent } from 'core-global-frontend-header';
import { BannerComponent } from '../banner/banner.component';
import { HeaderService } from 'core/libs/global/frontend/header/src/lib/header.service';
/**
 * @description The AppShellComponent is a component that displays the shell of the application.
 * @param title A string that represents the title of the application.
 * @param showLogo A boolean that represents if the logo should be displayed.
 * @param ui A string that represents the UI of the application.
 * @param route A string that represents the route of the application.
 * @param isResourceAccessAllowed A function that takes a resource name and returns an observable of a boolean. This function is used to determine if a user has access to a resource.  This approach is used to allow for dynamic access control providers (required to support RBAC and the any new implementations).
 * @param isDebug A boolean that represents if the application is in debug mode. Like the isResourceAccessAllowed, passing this boolean at the application level keeps the appshell components decoupled from the application services.
 */
@Component({
  selector: 'frontend-app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
  imports: [
    AngularMaterialModule,
    BannerComponent,
    CommonModule,
    GlobalFrontendGlobalStoreModule,
    HeaderComponent,
    NavigatorComponent,
    OverlayComponent,
    RouterModule,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppShellComponent {
  @Input() showLogo = true;
  @Input() ui = '';
  @Input() route = '';
  @Input() isDebug = false;
  @Input() showDebugToggle= false;
  @Input() showTicketCreationLinks = false;
  @Input() siteMap: SiteMapNode[] = [];
  @Input() menu: SiteMapNode[] = [];

  constructor(
    private _router: Router,
    private _headerService: HeaderService,
  ) {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._headerService.clear();
      }
    });
  }
}
