import { Injectable } from '@angular/core';
import { TokenCacheService } from '@nimbus/shared-lib';
import { Observable, ReplaySubject, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PixelAccessTokenHistoryModel } from '../models/pixel-access-token-history.model';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { ImpersonationTrackerService } from '../../../shared/services/impersonation-tracker.service';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';

@Injectable({
  providedIn: 'root',
})
export class PixelManagerCacheService {
  private _updatedAt: Date;
  private readonly _subscriptionManager = new SubscriptionManager();
  private readonly _accessTokensSubject = new ReplaySubject<PixelAccessTokenHistoryModel[]>();
  readonly accessTokens$ = this._accessTokensSubject.asObservable();
  isLoading$: Observable<boolean>;

  constructor(
    private _webServiceBuilderService: NimbusWebServiceBuilderService,
    private _ovs: ObjectValidatorService,
    private _tokenCacheService: TokenCacheService,
    private _impersonationTrackerService: ImpersonationTrackerService,
  ) {
    this.load(true);
  }

  load(refresh: boolean = false): void {
    if (
      !refresh &&
      this._ovs.isDefined(this._updatedAt) &&
      new Date(this._updatedAt.getTime() + 3600000) > new Date(Date.now())
    ) {
      return;
    }

    this.isLoading$ = of(true);
    this._subscriptionManager.registerMultiple([
      this._tokenCacheService.email
        .pipe(
          switchMap(emailID => this._webServiceBuilderService.builder
            .withUrl(`pixels/${this._ovs.isNullOrEmpty(this._impersonationTrackerService.user) ? emailID : this._impersonationTrackerService.user.email}/accesstokens`)
            .build()
            .get<any[]>({})
          ),
          map(pixelAccessTokens => pixelAccessTokens
            .map(pixelAccessToken => {
              pixelAccessToken.updatedDate =
                pixelAccessToken.updatedDate === null
                  ? pixelAccessToken.createdDate
                  : pixelAccessToken.updatedDate;
              return pixelAccessToken;
            }),
          ),
          tap(requestedAccessTokens => {
            this._accessTokensSubject.next(requestedAccessTokens);
            this._updatedAt = new Date(Date.now());
            this.isLoading$ = of(false);
          }),
          catchError(error => {
            this.isLoading$ = of(false);
            throw error;
          }),
        )
        .subscribe(),
      this.accessTokens$.subscribe(),
    ]);
  }
}
