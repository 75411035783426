<mat-tab-group headerPosition="below" class="debug-bar" animationDuration="0ms">
  <mat-tab label="Minimize"> </mat-tab>
  <mat-tab label="Environment">
    <p *ngIf="ovs.isDefined(configService)">
      <label>RBAC Application Id</label> {{ configService.getApplicationId() }}
    </p>
    <p><label>Front-end server:</label> {{ environment.name }}</p>
    <p><label>Front-end build:</label> {{ buildVersion }}</p>
    <p *ngIf="ovs.isDefined(environmentDetailsService.healthMonitor)">
      <label>Database server:</label> {{ environmentDetailsService.healthMonitor.database }}
    </p>
    <p *ngIf="ovs.isDefined(environmentDetailsService.healthMonitor)">
      <label>Back-end server:</label> {{ environmentDetailsService.healthMonitor.service }}
    </p>
    <p *ngIf="ovs.isDefined(environmentDetailsService.healthMonitor)">
      <label>Back-end build version:</label>
      {{ environmentDetailsService.healthMonitor.serverBuild }}
    </p>
    <p *ngIf="ovs.isDefined(environmentDetailsService.healthMonitor)">
      <label>Back-end environment (from serverless backend):</label>
      {{ environmentDetailsService.healthMonitor.environment }}
    </p>
    <p *ngIf="ovs.isDefined(apiEnvironment)">
      <label>Back-end environment (from partner-api backend):</label>
      {{ apiEnvironment }}
    </p>
    <p *ngIf="ovs.isDefined(environmentDetailsService.healthMonitor)">
      <label>Select back-end environment:</label>
      <select title="Select debug environment" (change)="onBuildChanged($event)">
        <option
          *ngFor="let environment of environmentDetailsService.healthMonitor.debugEnvironments"
          [value]="environment"
          [selected]="environment === environmentDetailsService.selectedDebugEnvironment">
          {{ environment }}
        </option>
      </select>
    </p>
  </mat-tab>
  <div *appAccessControl="'lib-impersonation'">
    <mat-tab label="Impersonation">
      <lib-impersonation [isDebug]="true"></lib-impersonation>
    </mat-tab>
  </div>
  <mat-tab label="Logging">
    <div class="logging">
      <div *ngFor="let log of loggingService.logs.reverse()">
        {{ log }}
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
<!-- <lib-impersonation-status></lib-impersonation-status> -->
