import { Inject, Injectable } from '@angular/core';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseWebService } from './base-web.service';
import { HttpClientQ } from './http/http-client.service';
import { Subject } from 'rxjs';
import {
  IEntitiesLoader,
  IEntityLoader,
  IEntityUpdater,
  IEntityCreator,
  IEntityDeleter,
  PaginationProperties,
} from './ientities';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { ConfigService } from 'core-global-frontend-config';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { HttpParams } from '@angular/common/http';

/**
 * @description This class is used to build a generic web service.
 */
@Injectable({
  providedIn: 'root',
})
export class NimbusWebServiceBuilderService {
  private _api = this._configService.getApi();

  get builder() {
    return new NimbusWebServiceBuilder(
      this._http,
      this._configService,
      this._ovs,
      this._paginationProperties,
      this._api,
      this._snackbarService,
    );
  }

  constructor(
    private _http: HttpClientQ,
    private _ovs: ObjectValidatorService,
    private _paginationProperties: PaginationProperties,
    private _configService: ConfigService,
    private _snackbarService: SnackbarService
  ) {
    _configService.configured$.subscribe(() => {
      this._api = _configService.getApi();
    });
   }
}

export class NimbusWebServiceBuilder {
  private _url = '';
  private _cacheEnable = false;
  private _enablePagination = false;
  private _errorLogging = true;


  constructor(
    private _http: HttpClientQ,
    private _configService: ConfigService,
    private _ovs: ObjectValidatorService,
    private _paginationProperties: PaginationProperties,
    private _api: string,
    private _snackbarService: SnackbarService
  ) { }

  noErrorLogging() {
    this._errorLogging  = false;
    return this;
  }

  withApi(api: string) {
    this._api = this._configService.getApi(api) ?? api;
    return this;
  }

  withUrl(url: string) {
    this._url = url;
    return this;
  }

  withCaching(cacheEnable: boolean = true) {
    if (this._ovs.isDefined(cacheEnable)) {
      this._cacheEnable = cacheEnable;
    }
    return this;
  }

  withPagination() {
    this._enablePagination = true;
    return this;
  }

  build() {
    return new NimbusWebService(
      this._http,
      (this._url.indexOf('http') === -1) ? this._api + '/' + this._url : this._url,
      this._cacheEnable,
      this._paginationProperties,
      this._enablePagination,
      this._snackbarService,
      this._errorLogging
    );
  }
}

export class NimbusWebService<T>
  extends BaseWebService
  implements
  IEntitiesLoader<T>,
  IEntityLoader<T>,
  IEntityUpdater,
  IEntityCreator,
  IEntityDeleter {
  entities$: Observable<T[]>;
  entity$: Observable<T>;
  isItemLoaded = false;
  getCollectionSubject = new Subject<T[]>();
  getSubject = new Subject<T>();

  constructor(
    private _http: HttpClientQ,
    private _url: string,
    private _cacheEnable: boolean,
    public override paginationProperties: PaginationProperties,
    public enablePagination: boolean,
    private _snackbarService: SnackbarService,
    private _errorLogging: boolean
  ) {
    super();
    this.entities$ = this.getCollectionSubject.asObservable();
    this.entity$ = this.getSubject.asObservable();
  }

  filter<T>(params: any = {}): Observable<T[]> {
    this._guardForValidUrl();
    this._apiCallInProgressSubject.next(true);
    return this._http.get(this._url, params).pipe(
      tap(_response => this._apiCallInProgressSubject.next(false)),
      map(response => this.handlePagination(response, this.enablePagination)),
      tap(response =>
        this.getCollectionSubject.next(
          this.enablePagination && !!response?.['results']
            ? response['results']
            : response,
        ),
      ),
      catchError(err => {
        this._apiCallInProgressSubject.next(false);
        throw err;
      }),
      shareReplay(this._cacheEnable ? 1 : 0),
    );
  }

  private _guardForValidUrl() {
    if (this._ovs.isNullOrEmpty(this._url)) {
      throw new Error('GenericWebService: No url provided.');
    }
  }

  get<T>(params: { [key: string]: string | number | boolean } | HttpParams): Observable<T> {
    this._guardForValidUrl();
    this._apiCallInProgressSubject.next(true);
    return this._http.get(this._url, params).pipe(
      tap(_response => this._apiCallInProgressSubject.next(false)),
      shareReplay(this._cacheEnable ? 1 : 0),
      tap(item => this.getSubject.next(item)),
      catchError(err => {
        if (this._errorLogging) {
          this._snackbarService.open(`Error calling ${this._url}.`);
        }
        this._apiCallInProgressSubject.next(false);
        throw err;
      }),
    );
  }

  delete<T>(params: {
    [key: string]: string | number | boolean;
  }): Observable<T> {
    this._guardForValidUrl();
    this._apiCallInProgressSubject.next(true);
    return this._http.delete(this._url, params).pipe(
      catchError(err => {
        this._apiCallInProgressSubject.next(false);
        throw err;
      }),
    );
  }

  save<T>(body: any): Observable<T> {
    this._guardForValidUrl();
    this._apiCallInProgressSubject.next(true);
    return this._http.put(this._url, this._ovs.cleanObject(body)).pipe(
      catchError(err => {
        this._apiCallInProgressSubject.next(false);
        throw err;
      }),
    );
  }

  create<T>(body: any): Observable<T> {
    this._guardForValidUrl();
    this._apiCallInProgressSubject.next(true);
    return this._http.post(this._url, this._ovs.cleanObject(body)).pipe(
      catchError(err => {
        this._apiCallInProgressSubject.next(false);
        throw err;
      }),
    );
  }
}
