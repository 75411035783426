<div class="grid-table-container">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    #tableSort="matSort"
    (matSortChange)="sortData($event)"
  >
    @for (column of columns; track column) {
      <ng-container [matColumnDef]="column.field">
        @if (column.sortable) {
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [matTooltip]="column.toolTip ?? ''"
            matTooltipPosition="above"
          >
            {{ column.label }}
            @if (column.filterable && enableColumnFilters) {
              <svg
                class="filter-btn__icon"
                (click)="openFilterDialog($event, column)"
                role="img"
              >
                <use xlink:href="assets/img/sprites.svg#filter-icon" />
              </svg>
            }
          </th>
        } @else {
          <th
            mat-header-cell
            *matHeaderCellDef
            [matTooltip]="column.toolTip ?? ''"
            matTooltipPosition="above"
          >
            {{ column.label }}
            @if (column.filterable && enableColumnFilters) {
              <svg
                class="filter-btn__icon"
                (click)="openFilterDialog($event, column)"
                role="img"
              >
                <use xlink:href="assets/img/sprites.svg#filter-icon" />
              </svg>
            }
          </th>
        }
        <td mat-cell *matCellDef="let rowForm; let rowIndex = index">
          <frontend-cell-component-injector
            [column]="column"
            [editable]="_editable && (column.editable ?? false)"
            [rowForm]="rowForm"
            [rowIndex]="rowIndex"
            [dataSource]="dataSource"
          >
          </frontend-cell-component-injector>
        </td
      ></ng-container>
    }
    <tr mat-header-row *matHeaderRowDef="columnsFields"></tr>
    <tr
      (click)="click(row)"
      mat-row
      *matRowDef="let row; columns: columnsFields; let i = index"
      [class]="{ 'odd-row': i % 2 === 0 }"
    ></tr>
  </table>
</div>
<mat-paginator
  *ngIf="!hidingPagination"
  #paginator="matPaginator"
  [pageSizeOptions]="[5, 10, 20]"
  showFirstLastButtons
  (page)="pageChanged($event)"
  aria-label="Select page"
>
</mat-paginator>
<button
  mat-flat-button
  *ngIf="_editable && dataSource.data.length"
  type="submit"
  color="primary"
  [disabled]="formArray.pristine"
  (click)="submit()"
>
  {{ submitButtonLabel }}
</button>
