import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DomainManagerPrefilterModel } from '../../models/domain-manager-prefilter.model';
import { DomainManagerPrefilterSchemaService } from '../../schemas/domain-manager-prefilter-schema.service';
import { DomainListCacheService } from '../services/domain-list-cache.service';
import { debounceTime } from 'rxjs/operators';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';

@Component({
  selector: 'app-domain-manager-formly-prefilters',
  templateUrl: './domain-manager-formly-prefilters.component.html',
  styleUrls: ['./domain-manager-formly-prefilters.component.scss']
})
export class DomainManagerFormlyPrefiltersComponent implements OnInit, OnDestroy {
  @Output() filter = new EventEmitter<DomainManagerPrefilterModel>();
  private _subscriptionManager = new SubscriptionManager();
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = this._domainManagerPrefilterSchema.getSchema();
  model: DomainManagerPrefilterModel;
  constructor(
    private _domainListCacheService: DomainListCacheService,
    private _domainManagerPrefilterSchema: DomainManagerPrefilterSchemaService
  ) {
    this.model = this._domainListCacheService.getDomainManagerPrefilterModel();
  }

  ngOnInit(): void {
    this._subscriptionManager.register(
      this.form.valueChanges.pipe(debounceTime(2000)).subscribe(filter => this.filter.emit(filter as DomainManagerPrefilterModel)));
  }

  onSubmit() {
    this._domainListCacheService.load(true).subscribe();
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }
}
