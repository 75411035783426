import { FullLanguageWebService } from './full-language-web.service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LanguageModel } from '@nimbus/global/backend/domain/languages';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { SubjectManager, SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';

@Injectable({
  providedIn: 'root'
})
export class FullLanguageCacheService {
  private readonly _subjectManager = new SubjectManager();
  private readonly _subscriptionManager = new SubscriptionManager();
  private readonly _loadingInProgressSubject = new BehaviorSubject<boolean>(false);
  private _fullLanguage$: Observable<LanguageModel[]>;
  isLoading$: Observable<boolean> = this._loadingInProgressSubject.asObservable();
  get fullLanguage(): Observable<LanguageModel[]> {
    return this._fullLanguage$;
  }
  constructor(private _fullLanguageWebService: FullLanguageWebService) {
    this._subjectManager.register('fullLanguage');
    this._fullLanguage$ = this._subjectManager.get('fullLanguage');
    this._subscriptionManager.registerMultiple([
      this.fullLanguage.subscribe(),
      this.getFullLanguageList().subscribe()
    ]);
  }

  private getFullLanguageList() {
    this._loadingInProgressSubject.next(true);
    return this._fullLanguageWebService.getFullLanguageList().pipe(
      tap(() => this._loadingInProgressSubject.next(false)),
      tap(fullLanguage => this._subjectManager.next('fullLanguage', fullLanguage))
    );
  }
}
