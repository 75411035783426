import { DomainConfigurationService } from './services/domain-configuration.service';
import { combineLatest, from, Observable, of } from 'rxjs';
import { DomainConfigurationSchemaService } from '../schemas/domain-configuration-schema.service';
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { IDockable } from '@nimbus/shared-lib';
import { DomainConfigurationWebService } from './services/domain-configuration-web.service';
import { DomainListCacheService } from '../domain-manager/services/domain-list-cache.service';
import { DomainModel } from '../models/domain.model';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { UpdateParkedDomain } from './models/update-parked-domain';

@Component({
  selector: 'app-domain-configuration',
  templateUrl: './domain-configuration.component.html',
  styleUrls: ['./domain-configuration.component.scss'],
})
export class DomainConfigurationComponent implements OnInit, IDockable {
  private _finalDomainUrl: string | undefined = '';
  private _subscriptionManager = new SubscriptionManager();
  private _domainId: number;
  isLoading$: Observable<boolean>;
  @Input() model: any;
  tabIndex: number;
  title: string;
  @Output() modelChange: EventEmitter<any>;
  get finalDomainUrl() {
    return this._finalDomainUrl;
  }
  selectedDomain: DomainModel | undefined;
  trafficSource: any;
  form = new FormGroup({});
  formModel: any = {};
  fields: FormlyFieldConfig[];
  options: FormlyFormOptions = {};

  constructor(
    private _domainConfigurationSchemaService: DomainConfigurationSchemaService,
    private _webService: DomainConfigurationWebService,
    private _domainConfigurationService: DomainConfigurationService,
    public ovs: ObjectValidatorService,
    public _domainListCacheService: DomainListCacheService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this._domainId = null;
    this.trafficSource = null;
    this._subscriptionManager.registerMultiple([
      of(this._webService.trafficSources)
        .pipe(
          tap(() => (this._domainId = this._domainConfigurationService.domainId)),
          tap(trafficSource => (this.trafficSource = trafficSource)),
          switchMap(() => combineLatest([
              this._domainListCacheService.getDomainBy(this._domainId),
              this._domainListCacheService.getPartnerIdByDomainId(
                this._domainId,
              ),
            ])
          ),
          switchMap(([domain, partnerId]) =>
            this._webService.getDomainById(partnerId, this._domainId).pipe(
              tap(results => {
                this.selectedDomain = domain;
                try {
                  const urlParamStr = JSON.parse(results.urlParameters);
                  const urlParamObj = JSON.parse(urlParamStr);
                  this.formModel = JSON.parse(urlParamObj.FormSchema);
                  this._finalDomainUrl = urlParamObj.Querystring;
                  if (typeof this.formModel !== 'object') {
                    throw new Error();
                  }
                  this._finalDomainUrl =
                    this._domainConfigurationService.backwardsCompatible(
                      this.formModel,
                      this.selectedDomain?.domainName,
                      this.selectedDomain?.keywordRecommendationMethod,
                    );
                } catch (ex) {
                  this.formModel = {};
                  this._finalDomainUrl = '';
                  this._domainConfigurationService.backwardsCompatible(
                    this.formModel,
                    this.selectedDomain?.domainName,
                    this.selectedDomain?.keywordRecommendationMethod,
                  );
                }

                this._domainConfigurationSchemaService
                  .getSchema(
                    this.selectedDomain?.domainName,
                    this.formModel,
                    this.trafficSource,
                  )
                  .then(fields => {
                    this.fields = fields;
                    this._domainConfigurationSchemaService.updateComplianceDefaults(
                      this.formModel,
                      this.options,
                      true,
                    );
                    this.setDefaultValue(
                      'eu_designated_domain',
                      domain.euDomain,
                    );
                    this.isLoading$ = of(false);
                    this._changeDetectorRef.markForCheck();
                    this._changeDetectorRef.detectChanges();
                  });
              }),
            ),
          ),
        )
        .subscribe()
    ]);
  }

  ngOnInit(): void {
    this.isLoading$ = of(true);
  }

  onModelChange(model) {
    this._domainConfigurationSchemaService.updateComplianceDefaults(
      this.formModel,
      this.options,
    );
    const parameter =
      this._domainConfigurationService.liveUpdateUrlParameters(model);
    this._finalDomainUrl = this.ovs.isNullOrEmpty(parameter)
      ? `${this.selectedDomain?.domainName}/`
      : `${this.selectedDomain?.domainName}/?${parameter}`;
  }

  onSave() {
    let parkedDomain: UpdateParkedDomain =
      this._domainConfigurationService.createParkedDomain(
        this._finalDomainUrl,
        this.formModel,
      );
    this.isLoading$ = of(true);
    from(
      this._domainListCacheService.getPartnerIdByDomainId(
        this.selectedDomain?.id,
      ),
    )
      .pipe(
        switchMap(partnerId =>
          this._webService.putParkedDomain(
            partnerId,
            this.selectedDomain?.id,
            parkedDomain,
          ),
        ),
        switchMap(() => this._domainListCacheService.load(true)),
        tap(() => {
          this._domainConfigurationService.closeConfigurationDock();
          this.selectedDomain = null;
          this.isLoading$ = of(false);
        }),
        catchError(err => {
          this.isLoading$ = of(false);
          throw err;
        }),
      )
      .subscribe();
  }

  private setDefaultValue(key: string, value: any): void {
    this.fields[0].fieldGroup.forEach(fg => {
      fg.fieldGroup.forEach(subfg => {
        const el = subfg.fieldGroup.find(k => k.key === key);
        if (this.ovs.isDefined(el)) {
          el['defaultValue'] = value;
        }
      });
    });
  }
}
