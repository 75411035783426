<div class="banner-wrap">
  <lib-banner></lib-banner>
</div>
<frontend-header
  [menu]="menu"
  [siteMap]="siteMap"
  [route]="route"
  [showLogo]="showLogo"
  [ui]="ui" [isDebug]="isDebug"
  [showDebugToggle]="showDebugToggle" [showTicketCreationLinks]="showTicketCreationLinks"
></frontend-header>
<router-outlet></router-outlet>
<frontend-overlay></frontend-overlay>
