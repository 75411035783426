import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import {
  ObjectValidatorService,
  SearchableListItemWithSelect,
  SortOption,
} from '@nimbus/shared-lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FullGeoCacheService } from '../../../../services/full-geo-cache.service';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';

@Component({
  selector: 'localization-geo',
  templateUrl: './geo.component.html',
  styleUrls: ['./geo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeoComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
  private readonly _subscriptionManager = new SubscriptionManager();
  readonly autoSort: SortOption = SortOption.AutoSort;
  isDisabled: boolean = true;
  items$: Observable<SearchableListItemWithSelect[]>;

  constructor(
    public ovs: ObjectValidatorService,
    public fullGeoCacheService: FullGeoCacheService,
  ) {
    super();
  }

  ngOnInit(): void {
    this._populateFullList();
    this._setDefaultValue();
  }
  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }

  private _setDefaultValue(): void {
    const defaultValue = this.field['defaultValue'];
    if (!this.field.formControl.value && defaultValue) {
      this.field.formControl.setValue(defaultValue);
    }
  }

  private _populateFullList(): void {
    this.isDisabled = false;
    this.items$ = this.fullGeoCacheService.fullGeo.pipe(
      map(fullGeos => {
        const availableGeos = fullGeos.filter(
          g => this.ovs.isDefined(g.name) && this.ovs.isDefined(g.twoLetterCode)
        );
        const uniqueGeoListByKey: any[] = [
          ...new Map(availableGeos.map(item => [item.name, item])).values()
        ];
        return uniqueGeoListByKey
          .map(geo => {
            return new SearchableListItemWithSelect(
              geo.twoLetterCode.toLowerCase().trim(),
              false,
              geo.name.trim()
            );
          })
          .sort((a, b) => a.display.localeCompare(b.display));
      })
    );
  }
}
