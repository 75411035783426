import { Injectable } from "@angular/core";
import { LocalStorageProxyBuilder } from 'core-libs-global-frontend-local-storage';
import { OverlayService } from '../overlay/overlay.service';

@Injectable()
export class StartupSplashScreenService {

  constructor(
    private _localStorageProxyBuilder: LocalStorageProxyBuilder,
    private _overlayService: OverlayService
  ) {
  }

  show(uniqueId: string, component: any, numberOfImpressions: number) {
    const localStorageProxy = this._localStorageProxyBuilder.build(uniqueId, 0);
    const retrieved = localStorageProxy.retrieve<number>();
    if (retrieved >= numberOfImpressions) {
      return;
    }
    localStorageProxy.saveState(retrieved + 1 );
    this._overlayService.openDialog(component, {width: '950px'});
  }
}
