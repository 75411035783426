<ng-container *ngIf="!(selectedDomain?.domainName | callbackRunnerPipe: ovs.isNullOrEmpty)">
  <div class="configuration-header-container">
    <div class="configuration-header-container-header">
      <span class="configuration-header-container-header-title">Configure Domain: </span
      >{{ selectedDomain?.domainName }}
    </div>
  </div>
</ng-container>
<div [class]="{ 'configuration-container': true, 'gray-loader': (isLoading$ | async) }">
  <ng-container *ngIf="!(selectedDomain?.domainName | callbackRunnerPipe: ovs.isNullOrEmpty)">
    <form [formGroup]="form" (ngSubmit)="onSave()" id="ngForm" #documentEditForm="ngForm">
      <formly-form
        [form]="form"
        [fields]="fields"
        [model]="formModel"
        [options]="options"
        (modelChange)="onModelChange(formModel)"></formly-form>
    </form>
    <div class="footer">
      <h3 class="sub-title">Share this generated Domain URL</h3>
      <div class="footer-detail">
        <textarea readonly class="footer-url">{{ finalDomainUrl }}</textarea>
        <div>
          <button type="submit" form="ngForm" class="footer-button">
            <div>Save</div>
          </button>
          <div class="footer-note">
            (This will close the configuration dock for domain
            {{ selectedDomain?.domainName }})
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
