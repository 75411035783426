<div class="documentation tab-content">
  <div>
    On any System1 hosted campaign, you can add tracking and reporting attributes by passing URL parameters.
    <br /><br />
    Compliance parameters such as <span class="documentation-highlighted-2">ref</span> and
    <span class="documentation-highlighted-2">rskey</span> are required. All other parameters are
    optional. The “Domain Manager” tab will allow you to configure your domains and guide you
    through setting up all of our parameters by clicking the “Configure” icon.
    Simply copy the link that is generated under “Target URL” after entering all fields.
    If you are using a 3rd party redirect for tracking, you will need to work with your
    redirect provider to pass the necessary information to our system. <br /><br />Note: Domains may
    expire if they do not receive adequate traffic over a period of a few months. Please always
    check that a domain is live and rendering System1's lander before relaunching traffic.
  </div>
  <br />
  <h3 class="documentation-sub-header-2">Required Parameters</h3>
  <div class="documentation-highlighted-2">ref:</div>
  <div class="documentation shifted">
    <div>
      If you are sending traffic to a Google campaign, you must pass the correct HTTP referrer.
    </div>
    <br />
    <div>1. Natural Referrer:</div>
    <p>
      Specify the appropriate referrer policy on the origin page using
      <a class="documentation-link" target="_blank" href="https://www.w3.org/TR/referrer-policy"><span
          class="documentation-link-text">https://www.w3.org/TR/referrer-policy</span></a>. Option “same-origin” is
      preferred as it preserves the full referrer URL.
    </p>
    <div>2. ref Parameter:</div>
    <p ngNonBindable>
      In order to pass accurate referrer information to Google, you must also pass the encoded
      referrer into the “ref=” parameter in the URL call to the Google Keyword lander.
      <br /><br />
      For native implementations, please use:
      <br />
      <span class="documentation italic"><span class="documentation-highlighted-2">ref=</span>outbrain-{{publisher_name}}</span><br />
      <span class="documentation italic"><span class="documentation-highlighted-2">ref=</span>taboola-&#123;site&#125;</span><br />
      <span class="documentation italic"><span class="documentation-highlighted-2">ref=</span>gdn-&#123;placement&#125;</span>
      <br /><br />
      For social implementations, please use:
      <br />
      <span class="documentation italic"><span class="documentation-highlighted-2">ref=</span>facebook-{{placement}}</span><br />
      <span class="documentation italic"><span class="documentation-highlighted-2">ref=</span>tiktok-__placement__</span>
      <br /><br />
      For content implementations, please pass:
      <br />
      <span class="documentation italic"><span class="documentation-highlighted-2">ref=</span>article URL</span>
      <br /><br />
      Additional implementations, please use:
      <br />
      <span class="documentation italic"><span class="documentation-highlighted-2">ref=</span>mediago-$&#123;PUBLISHER_NAME&#125; </span>
      <br />
      <span class="documentation italic"><span class="documentation-highlighted-2">ref=</span>zemanta-&#123;publisher&#125; </span>
    </p>
  </div>
  <div class="documentation-highlighted-2">rskey:</div>
  <div class="documentation shifted">
    <div ngNonBindable>
      Rskey (referral source key) is a required parameter that helps to align ads with user intent.
      Please pass the main search term you plan to target for your campaign. If you have questions
      please contact your account manager when choosing terms.
      <br /><br />
      Example:
      <br /><br />
      <p>
        campaigndomain.fyi/?<span class="documentation-highlighted-2">rskey=</span>search+term
      </p>
    </div>
  </div>
  <div class="documentation-highlighted-2">forcekey:</div>
  <div class="documentation shifted">
    <div ngNonBindable>
      This parameter allows you to select the search terms requested on the keyword lander. Please
      note that there is no way to ensure all terms will be honored. All terms must be relevant to
      the targeted vertical and domain name. It is required to include at least 3 forcekey terms.
      <br />

      It is strongly recommended to provide 3 to 5 forcekey terms when setting up campaigns.
      For forcekey terms to pass correctly, you must capitalize the letter following “forcekey” in the URL parameters.
      Please see the below example:
      <br /><br />
      Example:
      <br /><br />
      <p>
        campaigndomain.fyi/?
        <span class="documentation-highlighted-2">forcekeyA=</span>Term1&
        <span class="documentation-highlighted-2">forcekeyB=</span>Term2&
        <span class="documentation-highlighted-2">forcekeyC=</span>Term3&
        <span class="documentation-highlighted-2">forcekeyD=</span>Term4&
        <span class="documentation-highlighted-2">forcekeyE=</span>Term5
      </p>
    </div>
  </div>
  <br />
  <h3 class="documentation-sub-header-2">Tracking Parameters</h3>
  <div>
    Tracking parameters are optional, but you are encouraged to experiment to find effective
    combinations. You can pass a conversion tracking URL (postback URL) corresponding to each step of the System1
    flow &mdash; session (user lands on our keyword lander), search (user clicks a keyword),
    or click (user clicks on an ad). Please ensure that tracking URLs are prefixed with https:// and encoded.
  </div>
  <br />
  <div class="documentation-highlighted-2">impression_track_url:</div>
  <div>
    The URL you pass will be embedded on the keyword lander page as a tracking pixel.
    This would record sessions on your campaign.
  </div>
  <br />
  <div class="documentation-highlighted-2">search_track_url:</div>
  <div>
    The URL you pass will be embedded on the search page as a tracking pixel, firing each time users
    initiate a search that takes them to the SERP (Search Engine Results Page). This feature is not
    supported on all embedded templates. Contact your account manager if you would like this feature
    enabled for your campaigns.
  </div>
  <br />
  <div class="documentation-highlighted-2">click_track_url:</div>
  <div>
    The URL you pass will be pinged when the user clicks on a paying ad on a SERP via an onclick handler.
    <br />
    To see which searched keyword was clicked by a user, you can leverage the OMKEYWORD parameter.
    For search and click URLs, if the placeholder OMKEYWORD appears in the URL,
    it will be replaced by the searched keyword.
    <br /><br />
    Example:
    <br />
    <ul class="">
      <li ngNonBindable>
        impression_track_url=https://www.trackingsite.com/id/1234?event=session
      </li>
      <li ngNonBindable>
        search_track_url=https://www.trackingsite.com/id/1234?event=search&query=OMKEYWORD
      </li>
      <li ngNonBindable>
        click_track_url=https://www.trackingsite.com/id/1234?event=click&query=OMKEYWORD
      </li>
    </ul>
  </div>
  <br />
  <div class="documentation-highlighted-2">sub_id:</div>
  <div>
    Sub IDs allow you to segment the traffic and monitor performance at a finer grain such as offer, publisher, feed ID,
    etc.
    <p>Please note: We do not support session-level Sub IDs.</p>
    We accept upper and lowercase letters, digits, dashes, periods, tildes, and underscores. This is
    any character in the following set: [A-Za-z0-9.-~]. The full list of the 66 unreserved
    characters in RFC3986, is available here:
    <a class="documentation-link" target="_blank" href="http://tools.ietf.org/html/rfc3986#section-2.3"><span
        class="documentation-link-text">http://tools.ietf.org/html/rfc3986#section-2.3</span></a>.
    <br /><br />
    To pass multiple values in <span class="documentation-highlighted-2">sub_id</span>, use a bar ( | ) as a separator.
    This will result in the different parameters being separated by a comma in reporting.
    <br /><br />
    Example:
    <br /><br />
    <p>
      sub_id=network|accID_000|campID_&#123;campaignid&#125;
    </p>
    <br />
  </div>
</div>