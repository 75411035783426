import { KeywordSelection } from "../domain-configuration/models/keyword-selection.model";

export interface DomainResponseModel {
  partnerId: number;
  id: number;
  createdAt: string;
  keywordRecommendationMethod: number;
  orcaRequestId: string;
  orcaQueueId: string;
  staticKeywords: string[];
  euDomain: boolean;
  urlParameters: string;
  status: string;
  forceKey: boolean,
  facebookDomainVerificationCode: string;
  parkedDomain: string;
  updatedAt: string;
}

export class DomainModel {
  public forceKey!: boolean;

  constructor(
    public partnerId: number,
    public partnerName: string | undefined,
    public id: number,
    public status: string,
    public domainName: string,
    public targetUrl: string,
    public createdAt: string,
    public updatedAt: string,
    public keywordRecommendationMethod: number,
    public facebookDomainVerificationCode: string,
    public euDomain: boolean,
    public urlParameters: string,
    public staticKeywords: string[],
  ) {
    this.forceKey = Number(keywordRecommendationMethod) === KeywordSelection.query_string_keywords;
  }
}
