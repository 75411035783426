import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { SearchableListItemWithSelect, SortOption } from '@nimbus/shared-lib';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FullLanguageCacheService } from '../../../../services/full-language-cache-service';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';

@Component({
  selector: 'localization-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, OnDestroy
{
  private readonly _subscriptionManager = new SubscriptionManager();
  readonly autoSort: SortOption = SortOption.AutoSort;
  isDisabled: boolean = true;
  items$: Observable<SearchableListItemWithSelect[]>;
  private readonly vertical_subvertical_country =
    'vertical-subvertical-country';

  constructor(
    public ovs: ObjectValidatorService,
    public fullLanguageCacheService: FullLanguageCacheService,
  ) {
    super();
  }

  ngOnInit(): void {
    this._populateFullList();
    this._setDefaultValue();
  }
  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }

  private _setDefaultValue(): void {
    const defaultValue = this.field['defaultValue'];
    if (!this.field.formControl.value && defaultValue) {
      this.field.formControl.setValue(defaultValue);
    }
  }

  private _populateFullList(): void {
    this.isDisabled = false;
    this.items$ = this.fullLanguageCacheService.fullLanguage.pipe(
      map(languageList => {
        const availableLang = languageList.filter(
          l => this.ovs.isDefined(l.name) && this.ovs.isDefined(l.code),
        );
        const uniqueLangList: any[] = [
          ...new Map(availableLang.map(item => [item.name, item])).values(),
        ];
        return uniqueLangList
          .map(language => {
            return new SearchableListItemWithSelect(
              language.code.toLowerCase().trim(),
              false,
              language.name.trim(),
            );
          })
          .sort((a, b) => a.display.localeCompare(b.display));
      }),
    );
  }
}
