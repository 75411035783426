import { Component, Input } from '@angular/core';
import { LoggingService } from '../../core/logging-service/logging.service';
import { ObjectValidatorService } from '../../core/object-validator-service/object-validator.service';
import { EnvironmentDetailsService } from './services/environment-details.service';
import { ConfigService } from '../../core/config.service';
import { AccessControlService } from '../../features/access-control/access-control.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-debug-bar',
  templateUrl: './debug-bar.component.html',
  styleUrls: ['./debug-bar.component.scss']
})
export class DebugBarComponent {
  @Input() appVersion: string;
  @Input() buildVersion: string;
  @Input() environment: any = {};
  @Input() apiEnvironment: string;
  
  constructor(
    public ovs: ObjectValidatorService,
    public environmentDetailsService: EnvironmentDetailsService,
    public loggingService: LoggingService,
    public configService: ConfigService,
    public accessControlService: AccessControlService
  ) {
    this.environmentDetailsService.populateDebugBarOptions();
  }

  onBuildChanged(event) {
    this.environmentDetailsService.selectedDebugEnvironment = event.target.value;
  }
}
