@for (menuItem of menu$ | ngrxPush; track menuItem) {
  <ng-container class="menu-main">
    @if (
      menuItem.siteMapNodes &&
      ((menuItem?.siteMapNodes?.length ?? 0) > 0)
    ) {
      <div class="button-container">
        <button
          mat-menu-item
          class="button-with-icon nav-menu-item"
          [matMenuTriggerFor]="subMenu.menu"
        >
          <span mat-menu-item-text>
            {{ menuItem.label }}
            <svg
              class="menu-icon"
              width="11"
              height="12"
              viewBox="0 0 11 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3,5 l3,3 3,-3" stroke="#426bf3" stroke-width="1.25" />
            </svg>
          </span>
        </button>
        <frontend-sub-menu
          #subMenu
          [siteMapNode]="menuItem"
          [path]="path"
        ></frontend-sub-menu>
      </div>
    }
    @if (
      !(
        menuItem.siteMapNodes &&
        ((menuItem?.siteMapNodes?.length ?? 0) > 0)
      )
    ) {
      <a
        *ngIf="menuItem['path']"
        mat-menu-item
        class="nav-menu-item"
        [routerLink]="menuItem['path']"
        >{{ menuItem.label }}</a
      >
      <a
        *ngIf="menuItem['url']"
        mat-menu-item
        class="nav-menu-item"
        [href]="menuItem['url']"
        >{{ menuItem.label }}</a
      >
    }
  </ng-container>
}
