<div class="navigator-wrapper">
  <div class="navigator-section">
    <a routerLink="{{ route }}" *ngIf="showLogo && localUi">
      <div class="image-section">
        <img
          class="logo-sized"
          src="/assets/images/{{ localUi }}.svg"
          alt="ramp-logo"
        />
        <img
          class="by-s1-logo"
          src="/assets/img/by-system1.svg"
          alt="by-system1"
        />
      </div>
    </a>
    <div class="navigation-bar">
      <frontend-navigator
        [menu]="(menu$ | ngrxPush) ?? []"
        [ui]="localUi"
        [path]="path"
      ></frontend-navigator>
    </div>
    <div class="toolbar-area">
      <frontend-toolbar [isDebug]="isDebug" [showDebugToggle]="showDebugToggle" [showTicketCreationLinks]="showTicketCreationLinks"></frontend-toolbar>
    </div>
  </div>
</div>
<div>
  <ng-container *ngIf="subheading || imgSrc || heading">
    <div class="pageheader" *ngIf="heading">
      <div
        [class]="subheading || imgSrc ? 'pageheader' : 'pageheader__smaller'"
      >
        <div class="pageheader__text-holder">
          <div class="pageheader__aligner">
            <nimbus-breadcrumbs
              *ngIf="path && showBreadcrumbs"
              [path]="path"
            ></nimbus-breadcrumbs>
            <div class="pageheader__heading" *ngIf="heading">{{ heading }}</div>
            <div class="pageheader__subheading" *ngIf="subheading">
              {{ subheading }}
            </div>
          </div>
        </div>
        <img *ngIf="imgSrc" src="{{ imgSrc }}" />
      </div>
    </div>
  </ng-container>
</div>
