import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import {
  Observable,
  ReplaySubject,
  combineLatest,
  filter,
  map,
  tap,
} from 'rxjs';
import { CommonModule } from '@angular/common';
import { PushPipe } from '@ngrx/component';
import { BreadcrumbsComponent } from 'core-global-frontend-breadcrumbs';
import {
  SiteMapNode,
  NavigatorComponent,
} from 'core-global-frontend-navigation';
import { ToolbarComponent } from 'core-global-frontend-toolbar';
import {
  ObjectValidatorService,
  UtilityService,
} from 'core-global-frontend-object-validator';
import { HeaderService } from '../header.service';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';

@Component({
  selector: 'frontend-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    CommonModule,
    NavigatorComponent,
    RouterModule,
    ToolbarComponent,
    BreadcrumbsComponent,
    PushPipe,
  ],
  standalone: true,
})
export class HeaderComponent implements OnDestroy {
  private readonly _siteMapSubject = new ReplaySubject<SiteMapNode[]>();
  siteMap$: Observable<SiteMapNode[]> = this._siteMapSubject.asObservable();
  private readonly _menuSubject = new ReplaySubject<SiteMapNode[]>();
  menu$: Observable<SiteMapNode[]> = this._menuSubject.asObservable();
  @Input() showBreadcrumbs = false;
  @Input() set siteMap(value: SiteMapNode[]) {
    if (this._ovs.isNullOrEmpty(value)) {
      return;
    }
    this._siteMapSubject.next(value);
    this._changeDetectorRef.markForCheck();
    this._changeDetectorRef.detectChanges();
  }
  @Input() set menu(siteMapNodes: SiteMapNode[]) {
    if (this._ovs.isNullOrEmpty(siteMapNodes)) {
      return;
    }
    this._menuSubject.next(siteMapNodes);
    this._changeDetectorRef.markForCheck();
    this._changeDetectorRef.detectChanges();
  }
  private readonly _subscriptionManager = new SubscriptionManager();
  private readonly _uiSubject = new ReplaySubject<string>();
  localUi = '';
  readonly ui$ = this._uiSubject.asObservable() as any;
  @Input() isDebug = false;
  @Input() showDebugToggle= false;
  @Input() showTicketCreationLinks = false;
  @Input() set ui(value: string) {
    if (this._ovs.isNullOrEmpty(value)) {
      return;
    }
    this.localUi = value;
    this._uiSubject.next(value);
  }
  @Input() route = '';
  @Input() showLogo = true;
  private readonly _pageHeadersInfo$: Observable<SiteMapNode> =
    this._getPageHeader();
  heading: string | undefined;
  heading$: Observable<string | undefined> = this._pageHeadersInfo$.pipe(
    map(_pageHeadersInfo => _pageHeadersInfo?.title ?? _pageHeadersInfo?.label),
    tap(label => (this.heading = label)),
  );
  subheading: string | undefined;
  subheading$: Observable<string | undefined> = combineLatest([
    this._pageHeadersInfo$.pipe(
      map(_pageHeadersInfo => _pageHeadersInfo?.description),
    ),
    this._headerService.subheading$,
  ]).pipe(
    map(([subheading, headerServiceSubheading]) =>
      !this._ovs.isNullOrEmpty(headerServiceSubheading)
        ? headerServiceSubheading
        : subheading,
    ),
    tap(subheading => (this.subheading = subheading)),
  );
  imgSrc: string | undefined;
  imgSrc$: Observable<string | undefined> = this._pageHeadersInfo$.pipe(
    map(_pageHeadersInfo => {
      this.imgSrc = _pageHeadersInfo?.imgSrc;
      return _pageHeadersInfo?.imgSrc;
    }),
  );
  path: string | undefined;
  path$: Observable<string | undefined> = this._pageHeadersInfo$.pipe(
    map(_pageHeadersInfo => _pageHeadersInfo?.path),
    tap(path => (this.path = path)),
  );

  constructor(
    private _router: Router,
    private _ovs: ObjectValidatorService,
    private _headerService: HeaderService,
    private _utilityService: UtilityService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    this._subscriptionManager.registerMultiple([
      this.subheading$.subscribe(),
      this.imgSrc$.subscribe(),
      this.path$.subscribe(),
      this.heading$.subscribe(),
    ]);
  }

  private _getPageHeader(): Observable<SiteMapNode> {
    return combineLatest([
      this.siteMap$,
      this._router.events.pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
        map(event => event.url.split('?')[0]),
        filter(url => !!url),
      ),
    ]).pipe(
      map(([siteMapNodes, route]) => {
        const matchingNode = this._utilityService
          .flattenListNodes<SiteMapNode>(siteMapNodes)
          .find(
            node =>
              `/${node?.path}` === route || `/${node?.redirectTo}` === route,
          ) as SiteMapNode;
        return this._ovs.isNullOrEmpty(matchingNode)
          ? (this._utilityService
              .flattenListNodes<SiteMapNode>(siteMapNodes)
              .find(node =>
                node?.path?.startsWith(route.split('/')[1]),
              ) as SiteMapNode)
          : matchingNode;
      }),
    );
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }
}
