<ng-container
  *ngIf="showCreationForm()">
  <div [class]="(apiCallInProgress$ | async) ? 'gray-loader' : ''">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="centered-with-top-header">
      <div class="scrollable">
        <formly-form
          [model]="model"
          [fields]="fields"
          [options]="options"
          [form]="form"></formly-form>
      </div>
      <div class="domain-create-buttons container">
        <div class="domain-create-buttons divider">
          <div>
            <button
              mat-button
              type="button"
              (click)="addDomain()"
              *ngIf="canAddDomain()"
              id="add-more-domains"
              class="domain-create-buttons button">
              + Add more domains
            </button>
            <button
              mat-button
              type="submit"
              class="domain-create-buttons button create"
              id="create-domains"
              [disabled]="!checkValid() || (apiCallInProgress$ | async)">
              Create domain(s)
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-container>
<ng-container
  *ngIf="!showCreationForm()">
  <div class="centered-with-top-header" style="padding-top: 25px;">Your domain creation limits are exceeded. Please try again after midnight UTC or contact your account manager.</div>
</ng-container>
