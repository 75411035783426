import { Injectable, OnDestroy } from '@angular/core';
import { SubjectManager } from '@nimbus/global-frontend-subscription-manager';
import { Observable } from 'rxjs';

@Injectable()
export class PixelManagerEditService implements OnDestroy {
  private readonly _subjectManager = new SubjectManager();
  private _currentActiveId: number = NaN;

  get inEditMode$(): Observable<number | undefined> {
    return this._subjectManager.get('inEditMode');
  }

  constructor() {
    this._subjectManager.register('inEditMode');
  }

  ngOnDestroy(): void {
    this._subjectManager.clear();
  }

  updateEditState(id: number, state: boolean) {
    if (isNaN(this._currentActiveId) && state) {
      this._currentActiveId = id;
      this._subjectManager.next('inEditMode', id);
    } else if (id === this._currentActiveId && !state) {
      this._currentActiveId = NaN;
      this._subjectManager.next('inEditMode', undefined);
    }
  }
}
