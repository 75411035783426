import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import {
  TokenCacheService,
} from '@nimbus/shared-lib';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PartnerCacheService } from '../../../shared/services/partner-cache.service';
import { PixelAccessTokenModel } from '../models/pixel-access-token.model';
import { PixelManagerSchemaService } from '../schemas/pixel-manager-schema.service';
import { PixelManagerEditService } from '../services/pixel-manager-edit.service';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { PixelManagerCacheService } from '../services/pixel-manager-cache.service';
import { ImpersonationTrackerService } from '../../../shared/services/impersonation-tracker.service';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';

@Component({
  selector: 'app-pixel-manager-form',
  templateUrl: './pixel-manager-form.component.html',
  styleUrls: ['./pixel-manager-form.component.scss'],
})
export class PixelManagerFormComponent implements OnDestroy {
  form = new FormGroup({});
  model = {};
  options: FormlyFormOptions = {};
  fields = [];
  private readonly _subscriptionManager = new SubscriptionManager();

  protected readonly _apiCallInProgressSubject = new BehaviorSubject<boolean>(
    false,
  );
  readonly apiCallInProgress$: Observable<boolean> =
    this._apiCallInProgressSubject.asObservable();
  private _firstPartnerId: number;

  constructor(
    public _pixelManagerSchemaService: PixelManagerSchemaService,
    private _tokenCacheService: TokenCacheService,
    private _ovs: ObjectValidatorService,
    private _impersonationTrackerService: ImpersonationTrackerService,
    private _snackbarService: SnackbarService,
    private _pixelManagerCacheService: PixelManagerCacheService,
    private _webServiceBuilderService: NimbusWebServiceBuilderService,
    private _partnerCacheService: PartnerCacheService,
    public pixelManagerEditService: PixelManagerEditService,
  ) {
    this._subscriptionManager.register(
      this._partnerCacheService.partners$
        .pipe(
          tap(partners => {
            if (this._ovs.isArray(partners) && partners.length > 0)
              this._firstPartnerId = partners[0].id;
            this.fields = this._pixelManagerSchemaService.getSchema();
          }),
        )
        .subscribe(),
    );
  }

  submitRequest(): void {
    this._snackbarService.open(
      'Processing your request.',
      SnackBarType.working,
      3000,
    );
    this._apiCallInProgressSubject.next(true);
    this._subscriptionManager.register(
      this._tokenCacheService.email
        .pipe(
          switchMap(email => {
            const pixelAccessTokenRequest = (this.form.value as any)
              .pixelAccessTokenRequest;
            const buysideNetworkToken = (this.form.value as any)
              .buysideAccessToken;
            const partnerId = pixelAccessTokenRequest.partnerId;

            const requestBody = new PixelAccessTokenModel(
              this._ovs.isNumber(partnerId) ? partnerId : this._firstPartnerId,
              pixelAccessTokenRequest.buysideNetwork,
              pixelAccessTokenRequest.pixelId,
              buysideNetworkToken,
              null,
              this._ovs.isNullOrEmpty(this._impersonationTrackerService.user)
                ? email
                : this._impersonationTrackerService.user.email,
            );

            return this._webServiceBuilderService.builder
                  .withUrl(`pixels/${requestBody.email}/accesstokens`)
                  .build()
                  .create(requestBody);
          }),
          map(() => this._pixelManagerCacheService.load(true)),
          tap(() => {
            this._snackbarService.open(
              'Request processed!',
              SnackBarType.done,
              3000,
            );
            this.options.resetModel();
            this._apiCallInProgressSubject.next(false);
          }),
          catchError(err => {
            this._snackbarService.open('There was an error creating this mapping.  Please try adding a new Access Token or contact your Account Manager if you continue to see this error.', SnackBarType.error);
            this._apiCallInProgressSubject.next(false);
            return EMPTY;
          })
        )
        .subscribe(),
      'CreateAccessTokenRequest',
    );
  }

  canSubmit(): boolean {
    return this.form.valid;
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }
}
