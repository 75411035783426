import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { BasePageComponent } from '@nimbus/shared-lib';
import { PartnerCacheService } from '../../../shared/services/partner-cache.service';
import { HeaderService } from 'core/libs/global/frontend/header/src/lib/header.service';
import { PixelManagerBuysideNetworkService } from '../../../shared/services/pixel-manager-buyside-network.service';

@Component({
  selector: 'app-pixel-manager',
  templateUrl: './pixel-manager.component.html',
  styleUrls: ['./pixel-manager.component.scss']
})
export class PixelManagerComponent extends BasePageComponent implements OnDestroy, AfterViewInit {
  constructor(
    private _headerService: HeaderService,
    public pixelManagerService: PixelManagerBuysideNetworkService,
    public partnerCacheService: PartnerCacheService
  ) {
    super();
    this._headerService.setSubheading('System1 can host server-to-server conversion tracking on your behalf. Choose your buyside, then, provide your pixel ID and corresponding buyside access token.');
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit('pixel/manager');
  }

  ngOnDestroy(): void {
    this._headerService.setSubheading('');
  }
}
