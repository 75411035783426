import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { sample } from 'lodash';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

export interface DomainNameBuilderParams {
  language: string;
  vertical: string;
  geoTwoLetterCode: string;
}

@Injectable({
  providedIn: 'root',
})
export class DomainNameBuilderService {
  private _tldList: string[] = ['live','fyi','world','zone'];

  constructor(private _ovs: ObjectValidatorService) {}

  /**
   *
   * @param domainNameBuilderParams.language selected language
   * @param domainNameBuilderParams.vertical
   * @returns
   */
  buildDomainName(params: DomainNameBuilderParams): string {

    const vertical = params.vertical.trim().toLowerCase().replace(/[\s_&/,]+/g, '-');
    const geo = params.geoTwoLetterCode.toLowerCase();
    const language =
      !this._ovs.isNullOrEmpty(params.language) && params.language !== 'en'
        ? '-' + params.language
        : '';

    const hash = this._getRandom();

    const domainName = geo === 'us'
      ? `${vertical}${language}-${hash}.${sample(this._tldList)}`
      : `${vertical}-${geo}${language}-${hash}.${sample(this._tldList)}`;

    return domainName.toLowerCase();
  }

  private _getRandom(): string {
    return String(Math.floor(Math.random() * 8999999) + 1000000);
  }
}
