import { Inject, Injectable } from '@angular/core';
import { FullGeoCacheService } from './full-geo-cache.service';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { FullGeoWebService } from './full-geo-web.service';
import { FullLanguageCacheService } from './full-language-cache-service';
import { FullLanguageWebService } from './full-language-web.service';
import { PartnerCacheService } from './partner-cache.service';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';
import { ImpersonationTrackerService } from './impersonation-tracker.service';

interface DomainCacheServices {
  geoService: FullGeoCacheService | null;
  languageService: FullLanguageCacheService | null;
  partnerService: PartnerCacheService | null;
}

@Injectable({
  providedIn: 'root',
})
export class DomainCreatorServiceFactory {
    private domainCacheServices: DomainCacheServices = {
      geoService: null,
      languageService: null,
      partnerService: null,
    };

    constructor(
      @Inject(OKTA_AUTH) public _oktaAuth: OktaAuth,
      private _fullGeoWebService: FullGeoWebService,
      private _fullLanguageWebService: FullLanguageWebService,
      private _ovs: ObjectValidatorService,
      private _webServiceBuilderService: NimbusWebServiceBuilderService,
      private _impersonationTrackerService: ImpersonationTrackerService,
    ) {}

    init() {
      this._oktaAuth.authStateManager.subscribe(authState => {
        if (authState.isAuthenticated) {
          this.domainCacheServices.geoService = new FullGeoCacheService(this._fullGeoWebService);
          this.domainCacheServices.languageService = new FullLanguageCacheService(this._fullLanguageWebService);
          this.domainCacheServices.partnerService = new PartnerCacheService(this._oktaAuth, this._ovs, this._webServiceBuilderService, this._impersonationTrackerService);
        }
      });
    }

    getDomainCacheServices(): DomainCacheServices {
      return this.domainCacheServices;
    }
}
