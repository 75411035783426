import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { UpdateParkedDomain } from '../models/update-parked-domain';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';

@Injectable()
export class DomainConfigurationWebService {
  trafficSources!: any;

  constructor(private _webServiceBuilderService: NimbusWebServiceBuilderService) {}

  getTrafficSource(): Observable<any> {
    return this._webServiceBuilderService.builder
      .withUrl('traffic-sources')
      .build()
      .get({})
      .pipe(
        tap(trafficSources =>(this.trafficSources = trafficSources))
      );
  }

  putParkedDomain(
    partnerId: number | undefined,
    domainId: number | undefined,
    parkedDomain: UpdateParkedDomain,
  ) {
    return this._webServiceBuilderService.builder
      .withUrl(`partners/${partnerId}/parkeddomains/${domainId}`)
      .build()
      .save(parkedDomain);
  }

  getDomainById(partnerId: number, domainId: number): Observable<any> {
    return this._webServiceBuilderService.builder
      .withUrl(`partners/${partnerId}/parkeddomains/${domainId}`)
      .build()
      .get({});
  }
}
