import { BehaviorSubject, Observable } from 'rxjs';
import { FullGeoWebService } from './full-geo-web.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { SubjectManager, SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CountryModel } from '@nimbus/global/backend/domain/countries';

@Injectable({
  providedIn: 'root'
})
export class FullGeoCacheService {
  private readonly _subjectManager = new SubjectManager();
  private readonly _subscriptionManager = new SubscriptionManager();
  private readonly _loadingInProgressSubject = new BehaviorSubject<boolean>(false);
  private _fullGeo$: Observable<CountryModel[]>;
  isLoading$: Observable<boolean> = this._loadingInProgressSubject.asObservable();
  get fullGeo(): Observable<CountryModel[]> {
    return this._fullGeo$;
  }
  constructor(private _fullGeoWebService: FullGeoWebService) {
    this._subjectManager.register('fullGeo');
    this._fullGeo$ = this._subjectManager.get('fullGeo');
    this._subscriptionManager.registerMultiple([
      this.fullGeo.subscribe(),
      this.getFullGeoList().subscribe()
    ]);
  }

  private getFullGeoList() {
    this._loadingInProgressSubject.next(true);
    return this._fullGeoWebService.getFullGeoList().pipe(
      tap(() => this._loadingInProgressSubject.next(false)),
      tap(fullGeo => this._subjectManager.next('fullGeo', fullGeo))
    );
  }
}
