import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AccessControlService } from '@nimbus/shared-lib';
import { tap, filter, startWith, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import {
  DomainNameBuilderParams,
  DomainNameBuilderService,
} from '../domain-creator/services/domain-name-builder.service';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { PartnerCacheService } from '../../../shared/services/partner-cache.service';
import { DomainCreateLimiterService } from '../domain-creator/services/domain-create-limiter.service';

@Injectable()
export class DomainCreatorSchemaService {
  constructor(
    private _ovs: ObjectValidatorService,
    private _accessControlService: AccessControlService,
    private _domainNameBuilderService: DomainNameBuilderService,
    private _partnerCacheService: PartnerCacheService,
    private _domainCreateLimiterService: DomainCreateLimiterService,
  ) { }

  getSchema() {
    return [
      {
        key: 'domains',
        type: 'domain-creator-repeat',
        fieldArray: {
          fieldGroupClassName: 'field-group-css',
          fieldGroup: [
            {
              key: 'partnerId',
              type: 'partner-select',
              templateOptions: {
                label: 'Partner *',
                required: true,
                width: 100
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  field.parent.formControl.get('partnerId').valueChanges.pipe(distinctUntilChanged()).subscribe(partnerId => {
                    if (!this._ovs.isNullOrEmpty(partnerId)) this._partnerCacheService.setCurrentPartner(partnerId);
                    const domainName = field.parent.formControl.get('domainName').value;
                    if (!this._ovs.isNullOrEmpty(domainName)) {
                      const formIndex = field.parent.parent.fieldGroup.findIndex(section => section.fieldGroup[1].fieldGroup[1].fieldGroup[1].formControl.value == domainName);
                      this._domainCreateLimiterService.formDomainUpdated(partnerId, formIndex);
                      field.parent.formControl?.get('domainName')?.updateValueAndValidity();
                    }
                  });
                  field.parent.formControl.get('domainName').valueChanges.pipe(distinctUntilChanged(), debounceTime(500)).subscribe(domainName => {
                    if (!this._ovs.isNullOrEmpty(domainName)) {
                      const partnerId = field.parent.formControl.get('partnerId')?.value ?? 0;
                      const formIndex = field.parent.parent.fieldGroup.findIndex(section => section.fieldGroup[1].fieldGroup[1].fieldGroup[1].formControl.value == domainName);
                      this._domainCreateLimiterService.formDomainUpdated(partnerId, formIndex);
                      field.parent?.formControl?.get('domainName')?.updateValueAndValidity();
                    }
                  });
                }
              }
            },
            {
              fieldGroupClassName: 'second-row-wrapper-css',
              fieldGroup: [
                {
                  key: 'vertical',
                  defaultValue: '',
                  type: 'input-with-tooltip',
                  templateOptions: {
                    label: 'Vertical',
                    width: 100,
                    placeHolderText: 'Enter a vertical in the native language that you plan to target (i.e. used cars, dental implants, etc.)',
                  },
                },
                {
                  wrappers: ['warning-wrapper'],
                  fieldGroupClassName: 'warning-wrapper-css',
                  fieldGroup: [
                    {
                      key: 'warning',
                      defaultValue: '',
                      className: 'field-css field-warning',
                      type: 'warning',
                      templateOptions: {
                        closed: 'false',
                        message: 'If creating a custom domain, make sure it is relevant to the vertical'
                      }
                    },
                    {
                      key: 'domainName',
                      type: 'input-with-tooltip',
                      defaultValue: '',
                      className: 'field-css field-styles',
                      templateOptions: {
                        label: 'Domain Name *',
                        showInfoButton: true,
                        keepPlaceholder: true,
                        toolTip:
                          'The domain-name field will automatically populate with our suggestion, ' +
                          'but you are able to enter your own preferred domain name. However, ' +
                          'if the domain name you choose already exists, the purchase will fail, and you’ll need to start over!',
                        placeHolderText: 'The following top level domains are available: .live, .fyi, .world, or .zone',
                      },
                      validators: {
                        validation: [
                          'domain-name-terms-validator',
                          'domain-name-validator',
                          'domain-tld-validator',
                          'domain-limit-validator',
                          'domain-unique-validator'
                        ]
                      },
                      hooks: {
                        onInit: (field: FormlyFieldConfig) =>
                          field.parent.formControl.get('vertical').valueChanges.pipe(startWith(),
                            filter(
                              input =>
                                !this._ovs.isNullOrEmpty(input),
                            ),
                            tap(input =>
                              field.formControl?.setValue(
                                this._domainNameBuilderService.buildDomainName({
                                  vertical: input,
                                  language: 'en',
                                  geoTwoLetterCode: 'us',
                                } as DomainNameBuilderParams),
                              ),
                            ),
                            tap(() => field.form?.markAllAsTouched()),
                          ),
                      },
                    },
                  ],
                },
                {
                  fieldGroupClassName: 'second-row-wrapper-css',
                  fieldGroup: [
                  ],
                },
              ],
            },
            {
              fieldGroupClassName: 'third-row-wrapper-css',
              fieldGroup: [
                {
                  key: 'privacyRegion',
                  type: 'checkbox-type',
                  props: {
                    label: 'Privacy Region',
                    showInfoButton: true,
                    toolTip:
                      'Please let us know if traffic for this domain will target any privacy or GDPR-regulated ' +
                      'regions. Regions include EU, UK, Brazil, and Iceland.',
                  },
                },
              ],
            },
          ],
        },
      },
    ];
  }
}
