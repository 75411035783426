import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DownloadButtonComponent } from '../download-button/download-button.component';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from 'core-global-frontend-common-ui';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'frontend-actions-bar',
  templateUrl: './actions-bar.component.html',
  styleUrls: ['./actions-bar.component.scss'],
  standalone: true,
  imports: [
    DownloadButtonComponent,
    CommonModule,
    AngularMaterialModule,
    CommonModule,
  ],
})
export class ActionsBarComponent {
  @Input() useExternalDownload: boolean = false;
  @Input() showRefreshButton: boolean = false;
  @Input() title!: string;
  @Input() refreshButtonText: string = 'Refresh';
  @Output() download = new EventEmitter<boolean>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onRefreshButtonClicked = new EventEmitter<void>();
  refreshDate: string = new Date().toLocaleString();

  constructor(private _datePipe: DatePipe) {}

  refreshGrid() {
    this.onRefreshButtonClicked.emit();
    try {
      this.refreshDate = this._datePipe.transform(
        new Date().toLocaleString(),
        'h:mma',
      ) as string;
    } catch (err) {
      this.refreshDate = '';
    }
  }
}
